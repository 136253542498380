import React, { useRef, useState, useEffect } from 'react';
import './ShortVideosCarousel.css'; // Import the CSS file for styling
import HeroSectionBG from '../assets/HeroSectionBG.png'; // Import the placeholder image

function ShortVideosCarousel() {
    // Array of video URLs
    const videoUrls = [
        ,
        "https://i.imgur.com/kG6frd3.mp4",
        "https://i.imgur.com/GJwFDXS.mp4",
        "https://i.imgur.com/mMDGsIz.mp4",
        "https://i.imgur.com/reIJQwd.mp4",
        "https://i.imgur.com/MWCFA58.mp4",
        "https://i.imgur.com/qLBsvpD.mp4",
        "https://i.imgur.com/4oZUIr1.mp4",
        "https://i.imgur.com/Yq25uRB.mp4",
        "https://i.imgur.com/TGN7gdw.mp4",
        "https://i.imgur.com/Ttne2uJ.mp4",
        "https://i.imgur.com/KVpDx9v.mp4",
        "https://i.imgur.com/2AVSjuy.mp4",
        "https://i.imgur.com/yKpCapV.mp4",
        "https://i.imgur.com/v6LmmvZ.mp4",
        "https://i.imgur.com/AZwLP5B.mp4",
        "https://i.imgur.com/Agir8qw.mp4",
        "https://i.imgur.com/prPU8qB.mp4",
        "https://i.imgur.com/oKHZmXL.mp4",
        "https://i.imgur.com/GommGyg.mp4",
        "https://i.imgur.com/25cYrIs.mp4",
        "https://i.imgur.com/HzmvQkE.mp4",
        "https://i.imgur.com/X5pJSWY.mp4",
        "https://i.imgur.com/fDFuYkj.mp4",
        "https://i.imgur.com/IbNHAvT.mp4",
        "https://i.imgur.com/6554K1U.mp4",
        "https://i.imgur.com/VyhRTEm.mp4",
        "https://i.imgur.com/PQgkr1Y.mp4",
        "https://i.imgur.com/1dOEGrC.mp4",
        "https://i.imgur.com/eNWiGjI.mp4",
        "https://i.imgur.com/n4405o7.mp4",
        "https://i.imgur.com/KRLeUt4.mp4",
        "https://i.imgur.com/ffp04ui.mp4",
        "https://i.imgur.com/Jo0yzy8.mp4",
        "https://i.imgur.com/rttT4Ex.mp4"


    ];

    // Reference to the video carousel container
    const carouselRef = useRef(null);
    // Index of the currently visible videos
    const [currentIndex, setCurrentIndex] = useState(0);
    // URLs of the dynamically generated poster images
    const [posterImageUrls, setPosterImageUrls] = useState([]);
    // Flags to track whether actual poster is loaded for each video
    const [posterLoaded, setPosterLoaded] = useState(Array(videoUrls.length).fill(false));

    useEffect(() => {
        const generatePosterImages = async () => {
            const urls = [];
            for (let i = 0; i < videoUrls.length; i++) {
                const url = await generatePosterImage(videoUrls[i], i);
                urls.push(url);
            }
            setPosterImageUrls(urls);
        };
        generatePosterImages();
    }, []);

    const generatePosterImage = async (videoUrl, index) => {
        return new Promise((resolve) => {
            const video = document.createElement('video');
            video.src = videoUrl;
            video.crossOrigin = 'anonymous';

            // Check if the user agent indicates a mobile device
            const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

            // For mobile devices
            if (isMobile) {
                video.onloadeddata = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    const ctx = canvas.getContext('2d');

                    // Draw a frame from the video onto the canvas
                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

                    // Extract the frame as a base64 encoded data URL (poster)
                    const posterUrl = canvas.toDataURL();

                    // Set the poster URL in the state
                    setPosterImageUrls(prevUrls => {
                        const newUrls = [...prevUrls];
                        newUrls[index] = posterUrl;
                        return newUrls;
                    });

                    // Mark the poster as loaded
                    setPosterLoaded(prevFlags => {
                        const newFlags = [...prevFlags];
                        newFlags[index] = true;
                        return newFlags;
                    });

                    // Resolve the promise with the poster URL
                    resolve(posterUrl);
                };
            } else {
                // For desktop devices
                video.onloadedmetadata = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    const ctx = canvas.getContext('2d');

                    // Draw the first frame of the video onto the canvas
                    video.currentTime = 0;

                    // Once the video seeks to the first frame, draw it onto the canvas
                    video.onseeked = () => {
                        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

                        // Convert the canvas content to a data URL
                        const posterUrl = canvas.toDataURL();

                        // Set the poster URL in the state
                        setPosterImageUrls(prevUrls => {
                            const newUrls = [...prevUrls];
                            newUrls[index] = posterUrl;
                            return newUrls;
                        });

                        // Mark the poster as loaded
                        setPosterLoaded(prevFlags => {
                            const newFlags = [...prevFlags];
                            newFlags[index] = true;
                            return newFlags;
                        });

                        // Resolve the promise with the poster URL
                        resolve(posterUrl);
                    };

                    // In case of an error, resolve the promise with a default poster
                    video.onerror = () => {
                        console.error(`Error loading video: ${videoUrl}`);
                        resolve(HeroSectionBG); // Fallback to placeholder image
                    };

                    // Seek to the first frame to trigger drawing onto the canvas
                    video.currentTime = 0;
                };
            }

            // In case of an error, resolve the promise with a default poster
            video.onerror = () => {
                console.error(`Error loading video: ${videoUrl}`);
                resolve(HeroSectionBG); // Fallback to placeholder image
            };

            // Load the video
            video.load();
        });
    };

    const handleNext = () => {
        if (carouselRef.current) {
            let nextIndex = currentIndex + 1;
            if (nextIndex >= videoUrls.length) {
                nextIndex = 0;
            }
            setCurrentIndex(nextIndex);
            scrollToVideo(nextIndex);
            playVideo(nextIndex); // Automatically play the next video
        }
    };

    const handlePrevious = () => {
        if (carouselRef.current) {
            let nextIndex = currentIndex - 1;
            if (nextIndex < 0) {
                nextIndex = videoUrls.length - 1;
            }
            setCurrentIndex(nextIndex);
            scrollToVideo(nextIndex);
            playVideo(nextIndex); // Automatically play the previous video
        }
    };

    const playVideo = (index) => {
        const videoElements = document.querySelectorAll('.video-player');
        videoElements.forEach((video, i) => {
            if (i === index) {
                video.play();
            } else {
                video.pause();
            }
        });
    };

    const scrollToVideo = (index) => {
        const videoContainer = document.querySelector(`#video-container-${index}`);
        if (videoContainer) {
            videoContainer.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    };

    const handlePlay = (index) => {
        setCurrentIndex(index);
        scrollToVideo(index);
    };

    const handlePause = (index) => {
        // You can add additional logic here if needed
    };

    return (
        <div className="short-videos-carousel-wrapper">
            <div className="short-videos-carousel" ref={carouselRef}>
                {videoUrls.map((url, index) => (
                    <div className="video-container" id={`video-container-${index}`} key={index}>
                        <img
                            src={posterLoaded[index] ? posterImageUrls[index] : HeroSectionBG}
                            style={{ display: 'none' }} // Hide the image element
                            alt={`Poster ${index}`} // Provide an alternative text
                        />
                        <video
                            className="video-player"
                            controls
                            playsInline
                            poster={posterLoaded[index] ? posterImageUrls[index] : HeroSectionBG}
                            onPlay={() => handlePlay(index)}
                            onPause={() => handlePause(index)}
                        >
                            <source src={url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                ))}
            </div>
            <button className="carousel-control prev" onClick={handlePrevious}>Previous</button>
            <button className="carousel-control next" onClick={handleNext}>Next</button>
        </div>
    );
}

export default ShortVideosCarousel;
